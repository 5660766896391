.vertical-carousel {
    height: 400px;
    perspective: 1000px;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
  
  .carousel-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    transform-style: preserve-3d;
    transition: transform 0.5s ease-in-out, z-index 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .carousel-item-image {
    width: 40%;
    height: 60%;
    margin-right: 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .carousel-item-image img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
  
  .carousel-item-details {
    width: 40%;
    height: 60%;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .carousel-item-name {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .carousel-item-description {
    font-size: 1.2rem;
  }
  